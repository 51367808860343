<template>
    <Header />
    <Footer />
    <div class="page-container q-mt-xl">
        <div>
            <div class="flex column">
                <h6 class="q-mt-xs q-mb-xs q-ml-md text-weight-bold text-left">
                    {{ $t("Home.catalog") }}
                </h6>
            </div>
            <div class="row">
                <div class="product-page-options column q-mt-md">
                    <q-input class="omni-text-primary q-mx-md q-my-sm" style="min-width: 240px;" dense filled
                        v-model="search" @update:model-value="calculateProductsQueryResult()"
                        :placeholder="$t('BuyingProcess.products.search-product')" type="text">
                        <q-icon class="q-pa-none q-ma-none icon-white" name="search" size="24px"
                            style="position: relative; top: 7px" />
                    </q-input>
                    <q-select class="omni-text-primary q-mx-md q-my-sm" style="min-width: 240px;" dense filled
                        v-model="filterSelect" :options="filterSelectOptions"
                        :label="$t('BuyingProcess.products.filter-label')"
                        @update:model-value="calculateProductsQueryResult()" />
                    <q-select class="omni-text-primary q-mx-md q-my-sm" style="min-width: 240px;" dense filled
                        v-model="sortingSelect" :options="sortingSelectOptions"
                        :label="$t('BuyingProcess.products.sort-label')"
                        @update:model-value="calculateProductsQueryResult()" />
                </div>
                <!-- TODOS -->
                <div v-if="products.length > 0" class="row q-ma-md products-container flex">
                    <div v-for="(product, index) in productsQueryResult" :key="index" class="list-product cursor-pointer q-pa-sm"
                        id="list-products" v-show="product.visible">
                        <q-card flat class="q-px-md q-pb-md q-pt-sm">
                            <img
                                class="rounded-borders cursor-pointer product-image"
                                :src="product.images.length > 0
                                    ? getProductImageUrl(product.images[0])
                                    : defaultImage"
                                :alt="product.name"
                                loading="lazy" clickable
                                @click="showItem(product)"
                                :disabled="points.points.points >= product.value ? disabled : 0" />
                            <div class="product-summary">
                                <div class="text-subtitle2 omni-text-secondary text-left">
                                    {{ product.value }} {{ settings.currency_abbreviation }}
                                </div>
                                <div class="text-subtitle1 text-left">
                                    {{ product.name }}
                                </div>
                            </div>
                        </q-card>
                    </div>
                </div>
            </div>
        </div>


        <template v-if="productsQueryResult.length > 0"> </template>
        <div v-else>{{ $t("General.no-data") }}</div>

        <!-- Dialog que muestra ficha producto -->
        <div>
            <ProductPage v-model="dialog" :product="selectedProduct" :points="points" />
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions } from "vuex";
import { getProductImageUrl } from "@/services/helpers/ProductImages.js"

const Header = defineAsyncComponent(() =>
    import("../../shared/components/Header.vue")
);

const Footer = defineAsyncComponent(() =>
    import("../../shared/components/Footer.vue")
);

const ProductPage = defineAsyncComponent(() =>
    import("../components/ProductPage.vue")
);

export default {

    components: {
        Header,
        Footer,
        ProductPage,
    },
    data() {
        return {
            dialog: false,
            defaultImage: this.defaultImageApp,
            selectedProduct: null,
            disabled: null,
            productsQueryResult: [],
            search: "",
            filterSelect: this.$t("BuyingProcess.products.filter-all"),
            sortingSelect: this.$t("BuyingProcess.products.sort-a-z"),

        };
    },
    computed: {
        settings() {
            return this.$store.state.settings.settings;
        },
        products() {
            return this.$store.state.products.products;
        },
        points() {
            return this.$store.state.points.points;
        },
        filterSelectOptions() {
            return [this.$t("BuyingProcess.products.filter-all"), this.$t("BuyingProcess.products.filter-stock-available"), this.$t("BuyingProcess.products.filter-enough-points")];
        },
        sortingSelectOptions() {
            return [this.$t("BuyingProcess.products.sort-a-z"), this.$t("BuyingProcess.products.sort-z-a"), this.$t("BuyingProcess.products.sort-cheaper-first"), this.$t("BuyingProcess.products.sort-expensive-first")];
        }
    },

    methods: {
        ...mapActions("products", ["getProducts"]),
        ...mapActions("points", ["getPoints"]),

        getProductImageUrl,

        calculateProductsQueryResult() {
            let queryResult = [...this.products];

            queryResult = this.productsSearch(queryResult);
            queryResult = this.productsFilter(queryResult);
            queryResult = this.productsSort(queryResult);

            this.productsQueryResult = [...queryResult];
        },
        productsSearch(productsList) {
            return productsList.filter((product) => {
                return product.name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
        productsFilter(productsList) {
            switch (this.filterSelect) {
                // All
                case this.filterSelectOptions[0]:
                    return productsList;
                    break;
                // With Stock available
                case this.filterSelectOptions[1]:
                    return productsList.filter((product) => {
                        return product.stock > 0;
                    });
                    break;
                // Customer has enough points
                case this.filterSelectOptions[2]:
                    return productsList.filter((product) => {
                        return product.value <= this.points.points.points;
                    });
                    break;
            }

            return productsList;
        },
        productsSort(productsList) {
            const sortedList = [...productsList];

            switch (this.sortingSelect) {
                case this.sortingSelectOptions[0]: // A to Z
                    return sortedList.sort((a, b) => a.name.localeCompare(b.name));
                case this.sortingSelectOptions[1]: // Z to A
                    return sortedList.sort((a, b) => b.name.localeCompare(a.name));
                case this.sortingSelectOptions[2]: // Cheaper first
                    return sortedList.sort((a, b) => a.value - b.value);
                case this.sortingSelectOptions[3]: // Expensive first
                    return sortedList.sort((a, b) => b.value - a.value);
                default:
                    return sortedList;
            }
        },

        showItem(selectedProd) {
            this.dialog = true;
            this.selectedProduct = selectedProd;
        },
    },
    watch: {
        products: function (val) {
            this.calculateProductsQueryResult();
        },
        points: function (val) {
            this.calculateProductsQueryResult();
        },
    },
    mounted() {
        this.getProducts()
        this.getPoints();
        this.calculateProductsQueryResult();
    },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.products-container {
    max-width: 920px;
    height: 100%;
    overflow-x: hidden;
    justify-content: flex-start;
}

.list-product{
    min-width: 175px;
}

.page-container {
    padding-bottom: 80px;
}

select {
    border-radius: 10px;
    height: 40px;
    width: 215px;
    border-color: #c7c7c7;
    color: #909090;
}

.q-img,
img {
    height: 147px;
    margin: 0.5rem;
    object-fit: contain;
    width: 140px;
}

@media only screen and (max-width: 600px) {
    .list-product{
        width: 50%;
        min-width: unset;
    }
    .product-image {
        width: 100%;
        margin: 0;
    }
}

@media only screen and (min-width: 600px) {
    .q-img {
        width: 160px;
        height: 177px;
    }

    .text-subtitle1 {
        max-width: 120px;
    }
}

.product-summary {
    display: flex;
    flex-direction: column;
    height: 5.4em;
    overflow: hidden;
}

.text-subtitle1 {
    // white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 140px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1rem;
    word-wrap: break-word;
}

.text-subtitle2 {
    font-size: 14px;
    font-weight: 400;
    color: var(--font-color-primary);
}

</style>
